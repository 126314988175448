import { mytableGetList } from './curd';
import { request } from '@/utils/request';
import { storePromise } from '@tencent/iegsp-common/tools/store-promise';

// 写入供应商隐私信息
export const addPolicy = async (id) => {
  const res = await request.post({
    url: '/srv.modv2/add_data/ssc_supplier/supplier_privacy_policy',
    data: { suid: id },
  });
  return { ret: res.ret, list: res.data.list };
};

// 读取供应商隐私信息
export const getReadPolicy = async () => {
  const res = await mytableGetList({
    project: 'ssc_supplier',
    schema: 'supplier_privacy_policy',
  });
  return { ret: res.ret, list: res.data.list };
};

// 查询用户表
export async function getUserInfo(data = { filter: [] }) {
  const res = await mytableGetList({
    project: 'ssc_global',
    schema: 'user',
    filter: data?.filter || [],
  });
  return res;
}

// 查询文件上传
export async function getUploadFileList(data) {
  const res = await mytableGetList({
    project: 'ssc_supplier',
    schema: 'upload_file',
    page: 1,
    page_size: 100000,
    filter: [
      {
        field: 'object_id',
        op: '=',
        value: String(data.objectId),
      },
      {
        field: 'object_type',
        op: '=',
        value: data.objectType,
      },
      {
        field: 'object_table',
        op: '=',
        value: data.objectTable,
      },
    ],
  });
  return res?.data?.list;
}

// 获取物件参考图
export const getThingUpload = async (data) => {
  const res: any = await mytableGetList({
    project: __PROJECT__.SSC,
    schema: 'upload_file',
    filter: data.filter,
    relation: 1,
  });
  return res?.data?.list || [];
};
// 获取制作明细
export function getProduceBreakdown(data) {
  return mytableGetList({
    project: __PROJECT__.SSC_GLOBAL,
    schema: 'produce_breakdown',
    filter: data.filter,
    relation: data.relation,
  });
}
// 获取单位
export async function getUnits(data) {
  return mytableGetList({
    project: __PROJECT__.SSC_GLOBAL,
    schema: 'workload_unit',
    filter: data.filter,
  });
}
// 拉取合同价格库
export async function getPriceLibrary(data) {
  return mytableGetList({
    project: __PROJECT__.SSC,
    schema: 'price_library',
    relation: 1,
    filter: data.filter,
    sorter: data.sorter,
    pos: data.pos,
  });
}

function setUnits(params: any): Promise<Array<any>> {
  const paramsString = JSON.stringify(params);
  return storePromise({
    key: `unit_${paramsString}`,
    time: 0,
    cache: true,
    promise: () => getUnits(params),
  });
}

function setProduceBreakdown(params: any): Promise<Array<any>> {
  const paramsString = JSON.stringify(params);
  return storePromise({
    key: `breakdown_${paramsString}`,
    time: 0,
    cache: true,
    promise: () => getProduceBreakdown(params),
  });
}

export { setUnits, setProduceBreakdown };
