import { setAppInfo } from '@tencent/iegsp-common/app-info';
import { request } from '@/utils/request';
interface AppInfo {
  request: any;
  $t: Function;
}

setAppInfo('request', function (...args: [any, any]) {
  return request.request(...args);
});

export type { AppInfo };
