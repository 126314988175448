<template>
  <version system="supplier" :commit="commit" :versions="versions" />
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { getSiteEnv } from '@/utils/env';
import { request } from '@tencent/iegsp-common/request';
import version from '@tencent/iegsp-common/components/version/index.vue';
import { getParams } from '@tencent/iegsp-common/components/version/version';
const commit = ref({});
const versions = ref([]);
onMounted(() => {
  if (getSiteEnv() === 'test') {
    if (import.meta.env.VITE_GIT_COMMIT) {
      commit.value = import.meta.env.VITE_GIT_COMMIT;
    }
    request.post(getParams('supplier')).then((res) => {
      if (res.data?.list?.length) {
        versions.value = res.data.list.map((i) => ({ label: i.name, value: i.branch }));
      }
    });
  }
});
</script>
