import { createRouter, createWebHistory, RouteLocationNormalized } from 'vue-router';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import { getRoutes } from './modules/base';
import { permsBeforeEach, redirectOldPath } from '@tencent/iegsp-common/tools/utils/route';
import { QueryType } from '@tencent/iegsp-common/tools/utils/util';
import { acClient } from 'iegsp-api/git.woa.com/itrpcprotocol/iegsp/suppliercgi/ac/ac.http';
import { DialogPlugin } from 'tdesign-vue-next';

export const allRoutes = [...getRoutes()];

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL || '/'),
  routes: allRoutes,
  scrollBehavior() {
    return {
      el: '#app',
      top: 0,
      behavior: 'smooth',
    };
  },
});

NProgress.configure({ showSpinner: false });

const redirectPath = (to: RouteLocationNormalized) => {
  const commonQuery = {};
  if (to.query?.[QueryType.Language]) {
    commonQuery[QueryType.Language] = to.query[QueryType.Language] as string;
  }
  if (to.path === '/acceptance/businessAcceptance') {
    const { order_number: poId = '', delivery_number: dlId = '' } = to.query || {};
    if (poId && !dlId) {
      let poDlId = '';
      const storeKey = `redirect_dl_${poId}`;
      try {
        poDlId = window.localStorage.getItem(storeKey);
      } catch (e) {}
      if (poDlId) {
        return Promise.resolve({
          path: '/delivery/businessAcceptance/index',
          query: { [QueryType.dl_id]: poDlId, [QueryType.po_id]: poId, ...commonQuery },
        });
      }
      return acClient.CreateDeliveryNumber({ po_id: poId as string }, { showMsgToast: false }).then(([res]) => {
        if (res.dl_id) {
          poDlId = res.dl_id;
          try {
            window.localStorage.setItem(storeKey, poDlId);
          } catch (e) {}
          return {
            path: '/delivery/businessAcceptance/index',
            query: { [QueryType.dl_id]: poDlId, [QueryType.po_id]: poId, ...commonQuery },
          };
        }
        return Promise.reject(new Error('no dl_id'));
      });
    }
  }

  const oldRes = redirectOldPath(to, allRoutes);
  if (oldRes?.path) {
    return Promise.resolve({
      path: oldRes.path,
      query: { ...commonQuery, ...oldRes.query },
    });
  }

  return Promise.resolve();
};

router.beforeEach((to, from, next) => {
  NProgress.start();
  redirectPath(to)
    .then((res) => {
      if (res?.path) return next(res);
      return permsBeforeEach({
        to: to as any,
        from: from as any,
        router,
      })
        .then((res: any) => {
          if (res === true) return next();
          if (res?.path) return next(res);
          return next();
        })
        .catch(() => {
          next(false);
        });
    })
    .catch((err) => {
      const Dia = DialogPlugin({
        closeOnEscKeydown: false,
        closeOnOverlayClick: false,
        header: $t('警告'),
        theme: 'danger',
        body: err.msg || err.message || $t('错误，刷新重试'),
        closeBtn: false,
        cancelBtn: $t('回首页'),
        confirmBtn: $t('刷新'),
        onCancel: () => {
          Dia.destroy();
          router.push({ path: '/dashboard/base' });
        },
        onConfirm: () => {
          window.location.reload();
          Dia.destroy();
        },
        onClose: () => {
          Dia.destroy();
        },
      });
      next(false);
    });
});
router.afterEach(() => {
  NProgress.done();
});
export default router;
