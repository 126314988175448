import { AreaCode, AreaMap, getArea } from '@tencent/iegsp-common/tools/utils/area';
import { EnvCode, getSiteEnv } from '@tencent/iegsp-common/tools/env';
const TestSiteList = [
  {
    code: AreaCode.cn,
    name: AreaMap[AreaCode.cn],
    domain: '//test-cn-supplier-ssc.woa.com',
  },
  {
    code: AreaCode.ap,
    name: AreaMap[AreaCode.ap],
  },
  {
    code: AreaCode.eu,
    name: AreaMap[AreaCode.eu],
  },
  {
    code: AreaCode.na,
    name: AreaMap[AreaCode.na],
    domain: '//test-supplier-ssc.woa.com',
  },
];

const PreviewSiteList = [
  {
    code: AreaCode.cn,
    name: AreaMap[AreaCode.cn],
    domain: '//preview-cn-supplier-ssc.woa.com',
  },
  {
    code: AreaCode.ap,
    name: AreaMap[AreaCode.ap],
    domain: '//preview-ap-supplier-ssc.woa.com',
  },
  {
    code: AreaCode.eu,
    name: AreaMap[AreaCode.eu],
    domain: '//preview-eu-supplier-ssc.woa.com',
  },
  {
    code: AreaCode.na,
    name: AreaMap[AreaCode.na],
    domain: '//preview-na-supplier-ssc.woa.com',
  },
];

const ProdSiteList = [
  {
    code: AreaCode.cn,
    name: AreaMap[AreaCode.cn],
    domain: '//cn.ssc.tencent.com',
  },
  {
    code: AreaCode.ap,
    name: AreaMap[AreaCode.ap],
    domain: '//ap.ssc.tencent.com',
  },
  {
    code: AreaCode.eu,
    name: AreaMap[AreaCode.eu],
    domain: '//eu.ssc.tencent.com',
  },
  {
    code: AreaCode.na,
    name: AreaMap[AreaCode.na],
    domain: '//na.ssc.tencent.com',
  },
];

const getSiteList = () => {
  const siteEnv = getSiteEnv();
  if (siteEnv === EnvCode.test) return TestSiteList;
  if (siteEnv === EnvCode.preview) return PreviewSiteList;
  return ProdSiteList;
};

const getCurrentSite = () => {
  const area = getArea();
  return getSiteList().find((i) => i.code === area);
};

export { getArea, getSiteList, getCurrentSite };
