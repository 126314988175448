import { defineStore } from 'pinia';
import { store } from '@/store';
import { workflowClient } from 'iegsp-api/git.woa.com/itrpcprotocol/iegsp/suppliercgi/workflow/workflow.http';

type CountInfo = {
  /** 待接收订单 */
  order_toReceiveOrders_index: 0;
  /** 待接收订单-项目类 */
  order_toReceiveOrders_index_project: number;
  /** 待接收订单-素材类 */
  order_toReceiveOrders_index_thing: number;

  /** 待发起交付 */
  delivery_toInitiate_index: number;
  /** 待发起交付-交付被驳回 */
  delivery_toInitiate_index_back: number;
  /** 待发起交付-素材类 */
  delivery_toInitiate_index_thing: number;
  /** 待发起交付-素材类-发起交付 */
  delivery_toInitiate_index_thing_initiate: number;
  /** 待发起交付-素材类-交付被驳回 */
  delivery_toInitiate_index_thing_reject: number;
  /** 待发起交付-项目类 */
  delivery_toInitiate_index_project: number;
  /** 待发起交付-项目类-发起交付 */
  delivery_toInitiate_index_project_initiate: number;
  /** 待发起交付-项目类-交付被驳回 */
  delivery_toInitiate_index_project_reject: number;
  /** 确认验收金额 */
  delivery_confirmAmount_index: number;
  /** 待报价 */
  order_toQuoted_index: number;

  /** 订单变更 */
  order_change_index: number;
  /** 订单变更-素材类 */
  order_change_index_thing: number;
  /** 订单变更-素材类-变更驳回 */
  order_change_index_thing_reject: number;

  cn: number;
  na: number;
  eu: number;
  ap: number;
};

const DefaultCount = {
  /** 待接收订单 */
  order_toReceiveOrders_index: 0,
  /** 待接收订单-项目类 */
  order_toReceiveOrders_index_project: 0,
  /** 待接收订单-素材类 */
  order_toReceiveOrders_index_thing: 0,

  /** 待发起交付 */
  delivery_toInitiate_index: 0,
  /** 待发起交付-交付被驳回 */
  delivery_toInitiate_index_back: 0,
  /** 待发起交付-素材类 */
  delivery_toInitiate_index_thing: 0,
  /** 待发起交付-素材类-发起交付 */
  delivery_toInitiate_index_thing_initiate: 0,
  /** 待发起交付-素材类-交付被驳回 */
  delivery_toInitiate_index_thing_reject: 0,
  /** 待发起交付-项目类 */
  delivery_toInitiate_index_project: 0,
  /** 待发起交付-项目类-发起交付 */
  delivery_toInitiate_index_project_initiate: 0,
  /** 待发起交付-项目类-交付被驳回 */
  delivery_toInitiate_index_project_reject: 0,
  /** 确认验收金额 */
  delivery_confirmAmount_index: 0,
  /** 待报价 */
  order_toQuoted_index: 0,

  /** 订单变更 */
  order_change_index: 0,
  /** 订单变更-素材类 */
  order_change_index_thing: 0,
  /** 订单变更-素材类-变更驳回 */
  order_change_index_thing_reject: 0,

  cn: 0,
  na: 0,
  eu: 0,
  ap: 0,
};

export const useCountStore = defineStore('count', {
  state: () => {
    const countInfo: CountInfo = {
      ...DefaultCount,
    };
    return {
      countInfo,
    };
  },
  actions: {
    getCount() {
      const countKeys = Object.keys(DefaultCount);
      return workflowClient.GetTodoCount({ position: countKeys }).then(([res]) => {
        countKeys.forEach((key) => {
          const val = res?.[key] || res?.count?.[key] || 0;
          this.countInfo[key] = val;
        });
      });
    },
  },
  persist: false,
});

export function getCountStore() {
  return useCountStore(store);
}
