import Cookies from 'js-cookie';
import { replaceParams, getUrlParams } from '@tencent/iegsp-common/tools/url';
import { DialogPlugin } from 'tdesign-vue-next';
import { getCurrentSite } from './area';

function getHref() {
  return replaceParams({ succ: '', code: '', msg: '' }, window.location.href, true);
}

function getOrigin() {
  const locationOrigin = window.location.origin;
  if (locationOrigin.includes('local')) {
    return `https:${getCurrentSite().domain}`;
  }
  return locationOrigin;
}

function getJumpAuth() {
  const jumpUrl = `${getOrigin()}/srv.auth/jump_to_sms_auth?system=ssc&login_count=1&target=${encodeURIComponent(
    replaceParams({ login_count: '1' }, getHref(), true),
  )}`;
  console.log('login', jumpUrl);
  return jumpUrl;
}

function getLogoutUrl() {
  const logoutUrl = `${getOrigin()}/srv.auth/sms_auth_logout?callback_url=${encodeURIComponent(getJumpAuth())}`;
  console.log('login', logoutUrl);
  return logoutUrl;
}

function getCookieDomain() {
  const domain = window.location.hostname.split('.');
  return `.${domain.slice(domain.length - 2).join('.')}`;
}

let jumpToSmsLoading = false;

let failConfirmDia = null;

export const jumpToSms = () => {
  if (jumpToSmsLoading) return;
  jumpToSmsLoading = true;
  setTimeout(() => {
    jumpToSmsLoading = false;
  }, 2000);
  const lHref = window.location.href;
  const params = getUrlParams(lHref);
  if (params.login_count || lHref.includes('/404')) {
    if (!failConfirmDia) {
      const confirmDia = DialogPlugin({
        theme: 'warning',
        header: $t('继续登录'),
        body: $t('请清理缓存后，继续登录'),
        confirmBtn: $t('继续登录'),
        cancelBtn: $t('取消'),
        onConfirm: () => {
          logoutSms();
          confirmDia.destroy();
        },
      });
    }
    return;
  }
  Cookies.remove('smsid', { domain: getCookieDomain() });
  window.location.replace(getJumpAuth());
};

export const logoutSms = () => {
  Cookies.remove('smsid', { domain: getCookieDomain() });
  window.location.replace(getLogoutUrl());
};

// 在main.ts中，执行一次
export const initSms = () => {
  const params: any = getUrlParams(location.href);
  const smsid = Cookies.get('smsid');
  if (params.code === smsid && smsid) return;
  if (params.succ === '1' && params.code) {
    Cookies.set('smsid', params.code, { domain: getCookieDomain(), expires: 30 });
    window.location.replace(getHref());
    return;
  }
  if (params.succ === '0') {
    failConfirmDia = DialogPlugin({
      theme: 'warning',
      header: $t('登录失败'),
      body: $t('请清理缓存后，重新登录，错误信息：{0}', [params.msg || '']),
      confirmBtn: $t('重新登录'),
      closeBtn: false,
      cancelBtn: null,
      closeOnEscKeydown: false,
      closeOnOverlayClick: false,
      onConfirm: () => {
        logoutSms();
        failConfirmDia.destroy();
        failConfirmDia = null;
      },
    });
    return;
  }
  if (!smsid) {
    jumpToSms();
  }
};
