<template>
  <t-dialog :visible="visible" :header="$t('信息安全声明')" :close-btn="false" :width="720">
    <!-- 文本 -->
    <div class="mt-[20px]">
      <t-checkbox v-model="readed" class="align-bottom">{{ $t('我已阅读并同意') }}</t-checkbox>
      <a
        class="inline underline"
        href="https://doc.weixin.qq.com/doc/w3_AdoAhAYyAF8tibYFkHUQtmqOQYnEw?scode=AJEAIQdfAAo0Hy0fqMAdoAhAYyAF8"
        target="_blank"
        theme="primary"
        >{{ $t('腾讯供应商门户服务协议') }}</a
      >{{ $t('和')
      }}<a class="inline underline" href="https://www.tencent.com/en-us/supplier-privacy-policy.html" target="_blank"
        >{{ $t('腾讯供应商门户隐私声明') }}
      </a>
    </div>
    <!-- 按钮 -->
    <template #footer>
      <div class="flex">
        <t-button class="ml-auto" :disabled="!readed" @click="clickRead">{{ $t('确定') }}</t-button>
      </div>
    </template>
  </t-dialog>
</template>

<script lang="tsx" setup>
import { ref, onMounted } from 'vue';
import { useUserStore } from '@/store';
import moment from 'moment';
import { addPolicy, getReadPolicy } from '@/api';
import { getArea } from '@/utils/area';
import { storeToRefs } from 'pinia';
import { getUserInfo } from '@/api/user';
import { AreaCode } from '@tencent/iegsp-common/tools/utils/area';

const { userInfo } = storeToRefs(useUserStore());
const visible = ref(false);
const readed = ref(false);

const clickRead = () => {
  localStorage.setItem('read_safety_tips', moment().format('YYYY-MM-DD'));
  visible.value = false;
  addPolicy(userInfo.value.supplier_user_id);
};

onMounted(() => {
  getUserInfo()
    .then(() => getReadPolicy())
    .then(({ list }) => {
      const isReaded = list.find((item) => String(item.suid) === userInfo.value.supplier_user_id);
      const readed = isReaded;
      if (!readed && getArea() !== AreaCode.cn) {
        visible.value = true;
      }
    });
});
</script>
