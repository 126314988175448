import { storePromise } from '@tencent/iegsp-common/tools/store-promise';
import { confClient } from 'iegsp-api/git.woa.com/itrpcprotocol/iegsp/suppliercgi/conf/conf.http';
// 主要是为了发送一次新版本的请求，能够设置user
export function getUserInfo() {
  return storePromise({
    key: 'getUserInfo',
    cache: true,
    promise: () => confClient.QueryLoginInfo({}),
  });
}
