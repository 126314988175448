import { defineStore } from 'pinia';
import { RouteType, MenuRoute, withoutPerms, PermsStatusEnum } from '@tencent/iegsp-common/tools/utils/route';
import { getRoutes } from '@/router/modules/base';
import { SupplierUserRoleValue } from 'iegsp-api/git.woa.com/itrpcprotocol/iegsp/supplier/baseinfo/baseinfo';
import { getUserInfo } from '@/api/user';
import { flattenDeep } from 'lodash';

export type UserInfo = {
  name: string;
  login_type: string;
  supplier_id: string;
  supplier_user_id: string;
  roles: SupplierUserRoleValue[];
  supplier_name: string;
};

export const HasPermission = {
  [SupplierUserRoleValue.PRODUCER]: {
    allowed_all_front: false,
    front_path: ['/delivery/toInitiate/index', '/delivery/businessAcceptance/index', '/delivery/detail/index'],
  },
  [SupplierUserRoleValue.BUSINESS]: {
    allowed_all_front: true,
  },
};

const getRoleValue = (roles: SupplierUserRoleValue[]) => {
  if (!roles?.length) return SupplierUserRoleValue.NONE;
  if (roles.length === 1) return roles[0];
  if (roles.includes(SupplierUserRoleValue.BUSINESS)) return SupplierUserRoleValue.BUSINESS;
  if (roles.includes(SupplierUserRoleValue.PRODUCER)) return SupplierUserRoleValue.PRODUCER;
  return SupplierUserRoleValue.NONE;
};

export const checkHasPermission = (route: MenuRoute, inUserInfo?: UserInfo) => {
  if (withoutPerms(route)) return true;
  let userInfo = inUserInfo;
  if (!userInfo) {
    userInfo = useUserStore().userInfo;
  }
  const permissionItem = HasPermission[getRoleValue(userInfo.roles)];
  return permissionItem?.allowed_all_front || permissionItem?.front_path?.includes(route.path);
};

export const hasPathPermission = ({ path, userInfo }: { path?: string; userInfo: UserInfo }): boolean => {
  const routes = getRoutes();
  const routesChildren = flattenDeep(routes.map((i) => i.children || []));
  const pathRoute = routesChildren.find((i) => i.path === path);
  return pathRoute && checkHasPermission(pathRoute, userInfo);
};

export const useUserStore = defineStore('user', {
  state: () => ({
    userInfo: {} as UserInfo,
    permStatus: PermsStatusEnum.unready,
    menuRoutes: [] as MenuRoute[],
  }),
  actions: {
    getUserInfo() {
      return getUserInfo()
        .then(() => {
          this.permStatus = PermsStatusEnum.success;
        })
        .catch((err) => {
          if (![10003, 100000].includes(err?.data?.ret) && this.permStatus !== PermsStatusEnum.success) {
            this.permStatus = PermsStatusEnum.fail;
          }
          return Promise.reject(err);
        });
    },
    setUserInfo(logininfo) {
      if (logininfo) {
        try {
          const loginData = JSON.parse(logininfo);
          // const extra: Recordable = JSON.parse(decodeURIComponent(loginData.extra));
          const extendInfo: Recordable = loginData.extendInfo || {};
          if (extendInfo?.supplier_user_id) {
            this.permStatus = PermsStatusEnum.success;
            this.userInfo = {
              name: decodeURIComponent(loginData.nick || extendInfo.name || ''),
              login_type: loginData.logintype?.toLowerCase() || '',
              supplier_user_id: extendInfo.supplier_user_id,
              roles: extendInfo.supplier_role?.split(';').map((i) => Number(i)) || [],
              supplier_name: decodeURIComponent(extendInfo.supplier_name || ''),
              supplier_id: extendInfo.supplier_id,
            };
            this.menuRoutes = getRoutes()
              .map((i) => ({
                ...i,
                children: i.children?.filter((j) => {
                  const jType = j.meta.type as RouteType;
                  return (
                    [RouteType.MENU_ALL].includes(jType) || ([RouteType.MENU].includes(jType) && checkHasPermission(j))
                  );
                }),
              }))
              .filter((i) => i.children?.length);
          }
        } catch (e) {}
      }
    },
  },
  persist: false,
});
