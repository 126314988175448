export const PROJECT_IOMC_CONSUMESRV = 'iomc_consumesrv';
export const PROJECT_SSC = 'ssc_supplier';
export const PROJECT_SSC_GLOBAL = 'ssc_supplier_global';

export const PROJECT_CODES = {
  SSC: PROJECT_SSC,
  IOMC_CONSUMESRV: PROJECT_IOMC_CONSUMESRV,
  SSC_GLOBAL: PROJECT_SSC_GLOBAL,
};

declare global {
  interface Window {
    __PROJECT__: typeof PROJECT_CODES;
  }
}
