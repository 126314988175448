import { ITipInterceptor } from '@tencent/pmd-types';
import { useUserStore } from '@/store/modules/user';

export class LoginInfoInterceptor implements ITipInterceptor {
  interceptor(res: any): [boolean, any] {
    const userStore = useUserStore();
    userStore.setUserInfo(res.headers?.logininfo);
    return [false, res];
  }
}
