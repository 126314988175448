import { useUserStore } from '@/store/modules/user';
const initHelper = () => {
  const { userInfo } = useUserStore();
  if (userInfo?.supplier_user_id && window.AIHelpSupport) {
    const initConfig = {
      appKey: 'TENCENTIEGSPD_app_e65b3e33749e4d3aac68745a2af5a9dd',
      domain: 'tencentiegspd.aihelp.net',
      appId: 'tencentiegspd_platform_4d632b089ec3d4ae42b20e7eb036ca9b',
      appName: 'SSCM',
      language: 'zh-CN',
      entranceId: 'E001',
      userConfig: {
        userId: userInfo.supplier_user_id,
        userName: userInfo.name,
        userTags: userInfo.login_type,
        customData: {
          sign: `iegsp_${userInfo.supplier_user_id}`,
        },
      },
    };
    window.AIHelpSupport.init(initConfig);
  }
};
export { initHelper };
