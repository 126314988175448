import { v4 as uuid4 } from 'uuid';
import * as R from 'ramda';
import { request } from '@/utils/request';
import {
  getDefaultValue,
  originalValueToObject,
  parseFieldArgs,
  type2ComponentName,
  type2FilterType,
  IFieldType,
} from './util';
import { Result } from '@/types/axios.d';
import { Ref, ref } from 'vue';
import moment from 'moment';
import { getArea } from '@/utils/area';
import { storePromise } from '@tencent/iegsp-common/tools/store-promise';

const columns = [];

const generateUUID = () => {
  const id = uuid4();
  const prefix = id.substr(0, 6).toUpperCase();
  const suffix = id.substr(24, 6).toLowerCase();
  return `${prefix}-${suffix}`;
};

export type AfterFetcher<T = unknown> = (list: Ref<T[]>) => void;

export type CustomFetcher<T = unknown> = (params: IMytableGetListBody) => Promise<{
  list: T[];
  pageInfo: {
    total: number;
    page?: number;
  };
}>;

export type IFilter = {
  field: string;
  op: '=' | 'like' | '!=' | '>=' | '<=' | 'in' | '>' | '<' | 'is_not_null' | 'is_null'; // FIXME something else?
  g?: string;
  rel?: 'or';
  value: string | number | null;
};

export type ISorter = {
  field: string;
  sort: 'desc' | 'asc';
};

export interface IMytableGetListBody {
  [x: string]: any;
  project: string; // 项目名称
  schema: string; // 需要操作的表名
  join?: string; //
  filter?: IFilter[] | null; // 获取数据的筛选条件 [{"field":"name", "op":"=", "value":"test"}]
  filter_str?: string; // 复杂筛选结构 field1='abc'&score>1 | field2='efg'&score<=2
  pos?: string; // 后端为了适配特定场景的参数
  sorter?: { field: string; sort: string }; // 获取数据的排序方式 {"field":"id", "sort":"desc"}
  page?: number; // 获取数据页目
  page_size?: number; // 获取每页数据条数 默认100
  relation?: number; // 获取返回的类型 一般为 1
  relation_depth?: string; // 2层关联对象穿透
  include?: string; // 指定返回数据列表中每条数据包含的字段 'filed1,filed2'
  nocache?: number; // 是否需要缓存数据
  tree_key?: string; // 树状展开key
  openTree?: boolean; // 是否树状展开
  appkey?: string; // 项目密匙
  schemakey?: string; // 表密匙
}

export interface IMytableGetSumBody {
  project: string;
  schema: string;
  filter?: IFilter[];
  field: string;
}

export interface IMytableGetDetailBody {
  project: string;
  schema: string;
  relation?: 0 | 1;
  id: string | number;
  join?: string | Array<string>;
}

export interface IMytableAddDataBody {
  project: string; // 项目名称
  schema: string; // 需要操作的表名
  data: Recordable; // 需要新增的数据
  extra_add?: Array<{
    project: string;
    schema: string;
    data: Array<Recordable>;
  }>;
  extra_update?: Array<{
    project: string;
    schema: string;
    batch: Array<{
      id: string;
      filter?: Array<IFilter>;
      data: Recordable;
    }>;
  }>;
}
export interface IMytableAddBatchBody {
  project: string; // 项目名称
  schema: string; // 需要操作的表名
  data: string[]; // 批量新增的数据数组 ["{json字符串}","{json字符串}"]
}
export interface IMytableUpdateDataBody {
  project: string; // 项目名称
  schema: string; // 需要操作的表名
  id: string; // 待更新数据的主键
  data: Recordable; // 需要修改的数据
  extra_add?: Array<{ project: string; schema: string; data: Array<Recordable> }>;
  extra_update?: Array<{
    project: string;
    schema: string;
    batch: Array<{
      id: string;
      filter?: Array<IFilter>;
      data: Recordable;
    }>;
  }>;
  extra_put?: Array<{ project: string; schema: string; data: Array<Recordable> }>;
  put_rule?: Array<{ project: string; schema: string; ref_field: string; filter?: Array<IFilter> }>;
}
export interface IMytableUpdateBatch {
  project: string; // 项目名称
  schema: string; // 需要操作的表名
  batch: { id: string; data: string }[]; // 需要修改的数据数组 [{"id":"请替换为主键ID", "data": "{json格式的字符串}"}]
  pos?: string;
}
export interface IMytableDelDataBody {
  project: string; // 项目名称
  schema: string; // 需要操作的表名
  ids: string[]; // 待删除的主键数组
}
export interface IMytableConnectorBody {
  env?: string;
  connector: string; // 连接器名称
  body?: Recordable; // 连接器接口传的参数
  params?: Recordable; // 连接器接口传的参数
}

export type IRegions = 'cn' | 'eu' | 'na' | 'ap';
// 并行请求ts
export interface mytableParallelRequestsBody {
  path: string;
  args: {
    project?: string; // 项目名称
    schema?: string; // 需要操作的表名
    data?: string | string[]; // 需要修改的数据 {json格式的字符串}
    id?: string; // 待更新数据的主键
    batch?: { id: string; data: string }[]; // 需要修改的数据数组 [{"id":"请替换为主键ID", "data": "{json格式的字符串}"}]
    ids?: string[]; // 待删除的主键数组
    join?: string; //
    filter?: { field: string; op: string; value: string | null }[] | null; // 获取数据的筛选条件 [{"field":"name", "op":"=", "value":"test"}]
    filter_str?: string; // 复杂筛选结构 field1='abc'&score>1 | field2='efg'&score<=2
    sorter?: { field: string; sort: string }; // 获取数据的排序方式 {"field":"id", "sort":"desc"}
    page?: number; // 获取数据页目
    page_size?: number; // 获取每页数据条数 默认100
    relation?: number; // 获取返回的类型 一般为 1
    include?: string; // 指定返回数据列表中每条数据包含的字段 'filed1,filed2'
    nocache?: number; // 是否需要缓存数据
    tree_key?: string; // 树状展开key
    openTree?: boolean; // 是否树状展开
    field?: string; // 返回数据中的字段
    system?: string;
    area?: string;
    business?: string;
  };
}
// 获取全局唯一编号
export interface uniqueNumberBody {
  system: string; // SC
  area?: string; // CN/AP/EU/NR，表示中国/新加坡/欧洲/北美
  business: string; // PO/PA/AP/PR，表示订单/立项单/验收单/需求单
}

// 下载导入模板
export interface IMytableImportTemplateParams {
  // 自定义文件名
  filename: string;
  // 填充数据，json字符串"
  fill?: string;
}

// 创建自定义模板
export interface IMytableImportCustomTemplateParams extends IMytableImportTemplateParams {
  // 自定义模板列头
  define: Array<{
    id: string; // "列标识符",
    title: string; // "列名称",
    option?: string; // "下拉框配置(|分割多个选项)",
    rule?: string; // "date|required|number", 这里可以自由组合，比如 "date|required"，表示日期格式，而且必填
    default?: string; // "默认值"
  }>;
}

// 创建模型模板
export interface IMytableImportSchemaTemplateParams extends IMytableImportTemplateParams {
  project: string;
  schema: string;
}

export interface IMytableColumnRes {
  columns: Record<string, string | boolean | number | object[]>[];
  fields: Record<string, string | boolean | number | object[]>[];
  formData: Record<string, string | boolean>;
}

// 获取列表数据
export function mytableGetList<T = any>(data: IMytableGetListBody): Promise<{ data: T; ret: number; reqid: string }> {
  const joinField = columns[data.schema]?.find((col) => col?.colKey?.includes('___'))?.colKey;

  return new Promise((resolve, reject) => {
    request
      .post({
        url: `/srv.modv2/get_list/${data.project}/${data.schema}`,
        data: {
          project: data.project,
          appid: data?.project,
          schema: data.schema,
          page: data.page,
          page_size: data.page_size,
          nocache: data.nocache,
          relation: data.relation,
          relation_depth: data.relation_depth,
          include: data.include,
          filter: data.filter,
          filter_str: data.filter_str,
          pos: data.pos,
          sorter: data.sorter,
          join: data?.join ? data.join : joinField ? joinField?.split('___')[0] : undefined,
          tree_key: data.tree_key,
          appkey: data.appkey,
          schemakey: data.schemakey,
          type: data?.type,
        },
      })
      .then((res: any) => {
        if (res?.ret === 0) {
          resolve(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// 串行请求
export function mytableGetMultiList<T = unknown>(
  params: IMytableGetListBody[],
): Promise<{ data: Array<{ data: T; ret: number; reqid: string }>; ret: number; reqid: string }> {
  return request.post({
    url: '/srv.model/get_multi_list',
    data: { params },
  });
}

// 列表页面配置 包括过滤条件与展示列
export async function mytableGetListConfig(data: {
  project: string; // 项目名称
  schema: string; // 需要操作的模型
  table_name?: string; // 取具体哪个表，关系到过滤字段
  join?: string; // 连表的表名
  custom_config?: {
    // 搜索的字段
    search_fields?: string;
    // 展示的列字段
    display_fields?: string;
    // 默认展示几个筛选器
    default_show_search?: number;
    // 空值展示
    empty_text?: string;
    // 是否开启多选
    multiple?: boolean;
    // 立即获取选项的关联字段
    immediate_get_options_search?: string;
  };
}): Promise<any> {
  const NULL_VALUE = data?.custom_config?.empty_text ? data.custom_config.empty_text : 'N/A';
  let fetchFields: Record<string, any>[] = [];
  const res = await request.get({
    url: `/srv.modv2/get_schema_detail/${data.project}/${data.schema}${
      data.table_name ? `?table_name=${data.table_name}` : ''
    }`,
  });
  // 主表字段配置
  if (res.ret === 0) {
    fetchFields = R.concat(fetchFields, res?.data?.fields || []);
  }

  // 连接表配置
  let joinRes = null;
  if (data.join) {
    joinRes = await request.get({
      url: `/srv.modv2/get_schema_detail/${data.project}/${data.join}`,
    });

    if (joinRes?.ret === 0) {
      fetchFields = R.concat(
        fetchFields,
        (joinRes?.data?.fields || []).map((field: Record<string, any>) => ({
          ...field,
          id: `${data.join}.${field.id}`,
          isJoin: true,
        })),
      );
    }
  }

  // 所有字段
  if (res.ret === 0 && fetchFields.length) {
    let sorter = null;
    const formData = {};
    const { search_fields, display_fields } = res.data?.table_config || data.custom_config || {};
    const { default_show_search, multiple, immediate_get_options_search } = data.custom_config || {};
    // 生成一个map数据，用于快速过滤需要的字段
    const fieldsMap: Map<string, any> = new Map(
      fetchFields.map((field: Record<string, any>) => {
        const args = parseFieldArgs(field.args, field.type); // 字段配置
        const type = type2FilterType(field.type); // 类型
        const i18nLabel = field.isJoin ? `table.${field.id}` : `table.${data.schema}.${field.id}`;
        // 取第一个排序字段作排序
        if (!sorter && field.sort) {
          sorter = { field: field.id, sort: field.sort };
        }
        return [
          field.id,
          // 生成列表配置
          {
            ...field,
            name: i18nLabel,
            ellipsis: true,
            sorter: !!field.sort,
            type,
            args: { ...args, type: field.type },
          },
        ];
      }),
    );

    // 根据配置生产filterForm需要的Transformer函数
    function type2Transformer(
      type: string,
      field: string,
      multiple: boolean = false,
    ): (val: string | number | string[]) => IFilter | IFilter[] {
      switch (type) {
        case 'select':
          return function (value) {
            // 多选为数组，单选为字符串
            const val = multiple ? ((value as string[]) || []).join(',') : (value as string | number);
            const op = multiple ? 'in' : '=';
            if (val) {
              return { field, op, value: val };
            }
          };
        case 'input':
          return function (str: string) {
            const value = R.trim(str || '');
            if (value) {
              return { field, op: 'like', value };
            }
          };
        case 'date_range':
          return function (value) {
            const newValue = typeof value === 'string' ? value.split(',') : value;
            if (newValue && newValue[0]) {
              return [
                { field, op: '>=', value: `${newValue[0]} 00:00:00` },
                { field, op: '<=', value: `${newValue[1]} 23:59:59` },
              ];
            }
          };
        default:
          break;
      }
    }

    // 过滤出筛选项,默认用searchable属性
    let fieldsList = Array.from(fieldsMap.values()).filter((field) => field.searchable);
    // 如果search_fields有值则取这部分的配置
    const searchFieldList = (search_fields || '').split(',').filter((field: string) => !!field);
    if (searchFieldList.length) fieldsList = searchFieldList.map((field) => fieldsMap.get(field));

    const fields = (
      await Promise.all(
        fieldsList
          .filter((item) => item)
          .map(async (field: any, index: number): Promise<any> => {
            formData[field.id] = ''; // 表单数据

            const options = ref(field?.args?.options || []);

            const loading = ref(false);

            let transformer = type2Transformer(field.type, field.id, multiple);

            // 表示需要进行远程搜索
            let onSearch = null;
            if (field?.args?.appid) {
              let { name_field, value_field, appid, schemaid } = field.args;
              const { type, filter_rule, rank_field, rank_order } = field.args;
              // 优化可配置排序规则
              const sorter = rank_field && rank_order ? { field: rank_field, sort: rank_order } : null;
              const immediateGetOptions = immediate_get_options_search?.includes(field.id);
              const getOptions = async (search?: string) => {
                const filter: IFilter[] = [];
                if (search) {
                  filter.push({ field: name_field, op: 'like', value: String(search) });
                }
                // 采购经理、采购品类特殊处理
                if (['user', 'category'].includes(schemaid)) {
                  appid = 'ssc_supplier';
                  schemaid = 'supplier_data';
                  name_field = 'keyword';
                  value_field = 'display_value';
                  filter.push({ field: 'type', op: '=', value: field?.args?.schemaid === 'user' ? '4' : '5' });
                }
                // 获取数据
                const res = await mytableGetList<{ list: { name: string; id: number }[] }>({
                  project: appid,
                  schema: schemaid,
                  page_size: 50,
                  filter,
                  sorter,
                });
                return res.data.list.map((item) => ({
                  label: item[name_field],
                  value: String(item[value_field]),
                  raw: item,
                }));
              };
              // 远程搜索
              if (type === IFieldType.related) {
                // 支持配置了立即获取选项的关联对象获取默认选项
                if (immediateGetOptions) {
                  options.value = await getOptions();
                } else {
                  // 没配置默认选项的设置获取方法
                  onSearch = async (search?: string) => {
                    loading.value = true;
                    options.value = await getOptions(search);
                    loading.value = false;
                  };
                }
              }
              // 支持选项表获取默认选项
              if (type === IFieldType.select && schemaid === 'option') {
                const [, fieldCode, value] = filter_rule.match(/([^=]+)=(\d+)/);
                options.value =
                  (
                    await mytableGetList<{ list: { name: string; id: number }[] }>({
                      project: appid,
                      schema: schemaid,
                      filter: [{ field: fieldCode, op: '=', value: value }],
                    })
                  )?.data?.list || [];
                // 将获取到的数据存到field里, 给列表映射值
                field.select_options = options.value;
              }
            }

            // 针对几个字段做特殊处理, 后台配置成关联对象，但又需要在搜索时变成输入框形式
            // 大多数是各种单号
            if (['contract_number', 'order_number', 'order.contract_number'].includes(field.id)) {
              field.type = 'input';
              transformer = type2Transformer(field.type, field.id, multiple);
              onSearch = null;
            }

            return {
              field: field.id,
              label: field.name,
              type: field.type,
              options,
              filterable: !!onSearch,
              loading,
              onSearch,
              // 默认下拉是多选
              multiple: field.type === 'select' && multiple,
              removeable: index > (default_show_search > 3 ? default_show_search - 1 : 2),
              transformer,
            };
          }),
      )
    ).filter((filterItem) => filterItem.type); // 过滤掉没有类型的避免报错

    // 初始化表单列选项
    let columnsList = Array.from(fieldsMap.values());
    // 如果display_fields有值则取这部分的配置去过滤出展示列
    // 记录配置里缺的字段
    const missingField = [];
    const columnFieldList = (display_fields || '').split(',').filter((field: string) => !!field);
    if (columnFieldList.length)
      columnsList = columnFieldList
        .map((field) => {
          const res = fieldsMap.get(field);
          if (!res) missingField.push(field);
          return res;
        })
        .filter((item) => !!item);
    if (missingField.length) console.log('请求配置缺失字段', missingField);

    // 空值展示
    const nullValueShow = (value) => {
      const val = typeof value === 'string' ? value.toLocaleLowerCase() : value;
      const NULL_VALUE_LIST = ['', undefined, null, 'null'];
      return NULL_VALUE_LIST.includes(val) ? NULL_VALUE : value;
    };

    const columns = columnsList.map((field: any): any => {
      // 默认单元格渲染
      let cell = (_, { row }) => {
        const val = R.path(field.id.split('.'), row);
        return val || nullValueShow(val);
      };

      // 值映射单元格
      if (field.type === 'select') {
        const hasMap = (field?.select_options || []).length;
        const valueMap = hasMap ? {} : null;
        // 兼容值为数组的处理
        const getValue = (rowData) => {
          const val = R.path(field.id.split('.'), rowData);
          if (Array.isArray(val)) {
            return val
              .filter((item) => !!item)
              .map((item) => item[field?.args?.name_field])
              .join(',');
          }
          return val;
        };

        if (hasMap) {
          field?.select_options.forEach((item) => {
            valueMap[item.value] = item.label;
          });
        }

        cell = (_, { row }) => {
          const val = R.path(field.id.split('.'), row);
          return hasMap ? valueMap[val] || NULL_VALUE : nullValueShow(getValue(row));
        };
      }
      // 时间
      if (field.type === 'date_range') {
        cell = (_, { row }) =>
          row[field.id] ? moment(row[field.id]).format('yyyy-MM-DD') : nullValueShow(row[field.id]);
      }

      return {
        colKey: field.id,
        title: field.name,
        align: 'center',
        width: field.width || '240px',
        ellipsis: true,
        resizable: true,
        cell,
      };
    });
    return { columns, fields, formData, sorter };
  }

  throw new Error(res.msg);
}

// get sum
export function mytableGetSum<T = unknown>(data: IMytableGetSumBody): Promise<{ data: T; ret: number; reqid: string }> {
  return new Promise((resolve, reject) => {
    request
      .post({
        url: '/srv.model/get_sum',
        data: {
          project: data.project,
          schema: data.schema,
          field: data.field,
          filter: data.filter,
        },
      })
      .then((res: any) => {
        if (res?.ret === 0) {
          resolve(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// 获取列表数据
export function mytableGetDetail<T = unknown>(
  data: IMytableGetDetailBody,
): Promise<{ data: T; ret: number; reqid: string }> {
  return new Promise((resolve, reject) => {
    request
      .get({
        url: `/srv.modv2/get_detail/${data.project}/${data.schema}?id=${data.id}&relation=${
          R.isNil(data.relation) ? '1' : data.relation
        }`,
      })
      .then((res: any) => {
        if (res?.ret === 0) {
          resolve(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// 获取列表数据post
export function mytablePostDetail<T = unknown>(data): Promise<{ data: T; ret: number; reqid: string }> {
  return new Promise((resolve, reject) => {
    request
      .post({
        url: `/srv.modv2/get_detail/${data.project}/${data.schema}`,
        data,
      })
      .then((res: any) => {
        if (res?.ret === 0) {
          resolve(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// 获取列表配置
export async function mytableGetColumns(data: {
  project: string; // 项目名称
  schema: string; // 需要操作的表名
  join?: string; // 连表的表名
}): Promise<IMytableColumnRes> {
  const res = await request.get({
    url: `/srv.modv2/get_schema_detail/${data.project}/${data.schema}`,
  });
  // const uiInfo = res?.data.uiInfo;

  if (res?.ret === 0) {
    const fieldsMapArr = R.map(
      (item) => ({
        // 生成列表配置
        ...item,
        title: item.name,
        colKey: item.id,
        ellipsis: true,
        sorter: !!item.sort,
        schema: data.schema,
      }),
      res?.data.fields,
    ) as IMytableColumnRes['fields'];
    let fields = [];
    const formData = {};
    // 生成搜索框
    fields = fieldsMapArr
      // .filter(field => field.searchable && !field.isHidden && !virtualFieldRe.test(field.id) && field.type !== IFieldType.id)
      // .filter((field) => field.searchable)
      .map((field: any) => {
        const args = parseFieldArgs(field.args, field.type);
        // const value = initData.value?.[field.id] ?? getDefaultValue(field.type, field.args);
        const value = field.value ?? getDefaultValue(field.type, field.args);
        formData[field.id] = '';

        const i18nLabel = `table.${field.schema}.${field.id}`;

        return {
          id: field.id,
          key: field.id,
          title: i18nLabel,
          label: i18nLabel,
          type: field.type,
          component: type2ComponentName(field.type),
          value: originalValueToObject(value, field.type),
          options: args && args.options,
          args,
          required: !!field.required,
          desc: field.desc,
        };
      });
    // if (locale !== 'zh_cn' && uiInfo.multilingual?.lang) {
    //   const lang = uiInfo.multilingual?.lang;
    //   const key = R.keys(lang).findIndex((item) => item?.split('_')[0] === locale?.split('-')[0]);

    //   const multiLanguageFields = lang[R.keys(lang)[key]]?.fields;
    //   fieldsMapArr.forEach((item) => {
    //     multiLanguageFields?.[item.id] && (item.title = multiLanguageFields[item.id]?.name);
    //   });
    // }
    columns[data.schema] = R.clone(fieldsMapArr);
    return { columns: fieldsMapArr, fields, formData };
  }
  throw new Error(res?.msg);
}

// 新增数据
export function mytableAddData(
  data: IMytableAddDataBody,
  callBack?: any,
): Promise<{ ret: number; msg: string; data: string | number }> {
  return new Promise((resolve, reject) => {
    request
      .post({
        url: `/srv.modv2/add_data/${data.project}/${data.schema}`,
        data: {
          data: data.data,
          extra_add: data.extra_add,
          extra_update: data.extra_update,
        },
      })
      .then((res: any) => {
        if (res?.ret === 0) {
          if (callBack) {
            callBack();
          }
          resolve(res);
        } else {
          console.log('error:', res?.msg);
          reject(res);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });
}

// 批量新增
export function mytableAddBatch(data: IMytableAddBatchBody) {
  return new Promise((resolve, reject) => {
    request
      .post({
        url: `/srv.modv2/add_batch/${data.project}/${data.schema}`,
        data: {
          project: data.project,
          schema: data.schema,
          data: data.data,
        },
      })
      .then((res: any) => {
        if (res?.ret === 0) {
          resolve(res);
        } else {
          console.log('error:', res?.msg);
          reject(res);
        }
      })
      .catch((error) => {
        reject(error);
        console.log('error', error);
      });
  });
}

// 更新数据
export function mytableUpdateData(data: IMytableUpdateDataBody): Promise<Result> {
  return new Promise((resolve, reject) => {
    request
      .post({
        url: `/srv.modv2/update_data/${data.project}/${data.schema}`,
        data: {
          id: data.id,
          data: data.data,
          extra_add: data.extra_add,
          extra_update: data.extra_update,
          extra_put: data.extra_put,
          put_rule: data.put_rule,
        },
      })
      .then((res: any) => {
        if (res?.ret === 0) {
          resolve(res);
        } else {
          console.log('error:', res?.msg);
          reject(res);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });
}

// 批量更新
export function mytableUpdateBatch(data: IMytableUpdateBatch) {
  return new Promise((resolve, reject) => {
    request
      .post({
        url: `/srv.modv2/update_batch/${data.project}/${data.schema}`,
        data: {
          project: data.project,
          schema: data.schema,
          batch: data.batch,
          pos: data?.pos,
        },
      })
      .then((res: any) => {
        if (res?.ret === 0) {
          resolve(res);
        } else {
          console.log('error:', res?.msg);
          reject(res);
        }
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });
}

// 删除数据
export function mytableDelData(data: IMytableDelDataBody) {
  return new Promise((resolve, reject) => {
    request
      .post({
        url: `/srv.modv2/del_data/${data.project}/${data.schema}`,
        data: {
          project: data.project,
          schema: data.schema,
          ids: data.ids,
        },
      })
      .then((res: any) => {
        if (res?.ret === 0) {
          resolve(res);
        } else {
          console.log('error:', res?.msg);
          reject(res);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  });
}

export interface IConnectorResult<T = unknown> {
  ret: number;
  msg: string;
  content: T;
}
// myTable 连接器
export function mytableConnector(data: IMytableConnectorBody, region?: IRegions): Promise<IConnectorResult<unknown>> {
  const connector = `${region || getArea()}.${data.connector}`;
  return new Promise((resolve, reject) => {
    request
      .post({
        url: `/srv.connector/send_task?conn=${connector}`,
        data: {
          connector: `${connector}`,
          task_id: generateUUID(),
          env: 'release',
          body: JSON.stringify(data.body),
          params: JSON.stringify(data.params),
        },
      })
      .then((res: any) => {
        if (res?.ret === 0) {
          resolve(res?.data);
        } else {
          console.log('error:', res?.msg);
          reject(res);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  });
}

// 自定义接口
export function mytableSrvModel(url: string, data: any) {
  return new Promise((resolve, reject) => {
    request
      .post({
        url: `/srv.core/${url}`,
        method: 'POST',
        data: { ...data },
      })
      .then((res: any) => {
        if (res?.ret === 0) {
          resolve(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// 获取用户的个人信息
export function getCurrentUserInfo() {
  return storePromise({
    key: 'get_user_info',
    cache: true,
    promise: () =>
      request
        .post({
          url: '/srv.auth/get_user_info',
        })
        .then((res) => res.data),
  });
}
// 获取用户名单
export async function getStafftUserList(str: string) {
  type IUserName = string;
  type IUserFullName = string;
  type IUser = {
    user: [IUserName, IUserFullName];
  };
  return request.get<Result<IUser[]>>({
    url: '/srv.auth/get_staff_list',
    params: {
      q: str,
    },
    withCredentials: true,
  });
}
// cos 上传获取令牌
export async function getCosUpload(params) {
  return request.get({
    url: '/srv.core/get_cos_credentials',
    params: {
      config: params,
    },
  });
}
// 将文件信息提交给后端
export async function postFileAddData(data) {
  return request.get({
    url: '/srv.model/add_data',
    data,
  });
}

// 并行请求
export function mytableParallelRequests(data: mytableParallelRequestsBody[], callBack?: any) {
  return new Promise((resolve, reject) => {
    request
      .post({
        url: '/srv.merge',
        data,
      })
      .then((res: any) => {
        if (res?.ret === 0) {
          if (callBack) {
            callBack();
          }
          resolve(res);
        } else {
          console.log('error:', res?.msg);
          reject(res);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  });
}

// 冒泡排序

// 设置用户信息
// eslint-disable-next-line camelcase
export function setUserSession(staff_name: string): Promise<Result<string>> {
  return new Promise((resolve, reject) => {
    request
      .post({
        url: '/srv.auth/set_user_session',
        method: 'POST',
        // eslint-disable-next-line camelcase
        data: { staff_name },
      })
      .then((res) => {
        if (res?.ret === 0) {
          resolve(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// 获取组织架构信息
export function getOrgInfo(id) {
  return request.get({
    url: '/srv.auth/get_org_info',
    params: { id },
  });
}
export function getOptions(params) {
  return request.post({
    url: '/srv.core/get_options',
    data: {
      project: 'mytable',
      schema: 'option',
      page: 1,
      page_size: 100000,
      filter_str: params.filter_str,
    },
  });
}
// 获取全局唯一编号
export function getUniqueNumber(params: uniqueNumberBody) {
  return request.get({
    url: '/srv.core/create_number',
    params: {
      system: params.system,
      // FIXME this arg from env
      // area: params.area || 'cn',
      area: 'ap',
      business: params.business,
    },
  });
}

// 导出文件 - 现有数据库api
export function getExportList<T = unknown>(
  data: IMytableGetListBody,
): Promise<{ data: T; ret: number; reqid: string }> {
  return new Promise((resolve, reject) => {
    request
      .post({
        url: '/srv.core/export_list',
        data: {
          project: data.project,
          schema: data.schema,
          page: data.page,
          page_size: data.page_size,
          nocache: data.nocache,
          relation: data.relation,
          include: data.include,
          filter: data.filter,
          filter_str: data.filter_str,
          sorter: data.sorter,
          join: data.join,
          tree_key: data.openTree ? data.tree_key : '',
          appkey: data.appkey,
          schemakey: data.schemakey,
        },
      })
      .then((res: any) => {
        if (res?.ret === 0) {
          resolve(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// 下载模型模板
export function getImportSchemaTemplate<T = unknown>(
  data: IMytableImportSchemaTemplateParams,
): Promise<{ data: T; ret: number; reqid: string }> {
  return new Promise((resolve, reject) => {
    request
      .post({
        url: '/srv.core/get_import_template',
        data,
      })
      .then((res: any) => {
        if (res?.ret === 0) {
          resolve(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// 下载自定义模板
export function getImportCustomTemplate<T = unknown>(
  data: IMytableImportCustomTemplateParams,
): Promise<{ data: T; ret: number; reqid: string }> {
  return new Promise((resolve, reject) => {
    request
      .post({
        url: '/srv.core/create_template',
        data,
      })
      .then((res: any) => {
        if (res?.ret === 0) {
          resolve(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// 解析excel
export function parseExcel<T = unknown>(data: {
  link: string;
}): Promise<{ msg?: string; correct?: number; link?: string; data: T; ret: number; reqid: string }> {
  return new Promise((resolve, reject) => {
    request
      .post({
        url: '/srv.core/parse_excel',
        data,
      })
      .then((res: any) => {
        if (res?.ret === 0) {
          resolve(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// 获取待办数量：
export function getTodoCount(data): any {
  data = data.map((item) => ({
    ...item,
    project: 'ssc_supplier',
    page: 1,
    page_size: 999,
  }));
  return new Promise((resolve, reject) => {
    request
      .post({
        url: '/srv.workflow/get_my_todo_count',
        data: {
          biz_rule: data,
        },
      })
      .then((res: any) => {
        if (res?.ret === 0) {
          resolve(res?.data?.list);
        } else {
          console.error(res);
          resolve(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// 获取访问资源地址
export function mytableDownloadCosFile(params: { id?: string; url?: string }) {
  const { id, url } = params;
  const key = id ? 'id' : 'link';
  // const value = id ? id : url.replace('https://', '');
  const value = id ? id : url;
  return new Promise<string>((resolve) => {
    request
      .get(
        {
          url: `/srv.core/download_cos_file?${key}=${value}`,
          responseType: 'blob',
        },
        { isReturnNativeResponse: true },
      )
      .then((res: any) => {
        const { responseURL } = res.request;
        resolve(responseURL);
      })
      .catch((error) => {
        console.log('error', error);
      });
  });
}
