<template>
  <t-config-provider :global-config="globalConfig">
    <div :class="[mode, 'h-full', 'w-full']">
      <router-view />
    </div>
    <dragHelper />
    <version></version>
    <area-vue :domains="['ssc.tencent.com']" :sites="getSiteList()" :area="getArea()" />
  </t-config-provider>
</template>
<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useSettingStore } from '@/store';
import enConfig from 'tdesign-vue-next/es/locale/en_US';
import zhConfig from 'tdesign-vue-next/es/locale/zh_CN';
import dragHelper from '@/components/dragHelper.vue';
import { getGlobalConfig } from '@tencent/iegsp-common/i18n';
import version from '@/components/version/index.vue';
import { useRoute } from 'vue-router';
import { getArea, getSiteList } from './utils/area';
import areaVue from '@tencent/iegsp-common/components/common/area.vue';

const route = useRoute();

console.info('[system]:', import.meta.env.VITE_GIT_COMMIT);

const store = useSettingStore();

const mode = computed(() => store.displayMode);

const globalConfig = computed(() => getGlobalConfig({ enConfig, zhConfig }));

onMounted(() => {
  window.addEventListener('beforeunload', (event) => {
    if (route.meta?.leave) {
      const message = $t('确定要离开吗？');
      event.returnValue = message;
      return message;
    }
  });
});
</script>
<style lang="less" scoped>
#nprogress .bar {
  background: var(--td-brand-color) !important;
}
</style>
