import { ITipInterceptor } from '@tencent/pmd-types';
import { jumpToSms } from '@/utils/login';

class ResponseInterceptor implements ITipInterceptor {
  interceptor(res: any): [boolean, any] {
    const { data } = res as any;
    // sscm、iomc、游戏人生
    const { ret } = data;
    if (ret === 10003 || ret === 100000) {
      jumpToSms();
      return [true, res];
    }
    return [false, res];
  }
}

export { ResponseInterceptor };
