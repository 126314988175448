import { initAegis, getAegis, setConfig } from '@tencent/iegsp-common/tools/utils/galileo';
import { getUserInfo } from '@/api/user';
import { useUserStore } from '@/store';

function init(app) {
  const { userInfo } = useUserStore();
  initAegis({
    id: 'SDK-c8471ebaf23171859daf',
    uid: userInfo?.supplier_user_id,
    app,
  });
  getUserInfo().then(() => {
    const { userInfo } = useUserStore();
    if (userInfo?.supplier_user_id) {
      setConfig({ uid: userInfo?.supplier_user_id });
    }
  });
}

export { getAegis, init };
